import React from "react"
import "./index.scss"

export default function Key({}) {
  return (
    <div className="key">
      <ul>
        <li>Very Believeable</li>
        <li>Moderately Believeable</li>
        <li>Somewhat Believeable</li>
        <li>Not Terribly Believeable</li>
        <li>Very Unbelieveable</li>
      </ul>
    </div>
  )
}
