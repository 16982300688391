import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

export default function SightingCard({ opened = false, post, handleClose }) {
  const {
    dateofSighting,
    description,
    featureImage,
    headline,
    locationText,
    believabilityRating,
  } = post
  return (
    <div className={` ${opened ? "showOpen" : ""} sighting-card`}>
      <span className="postClose" onClick={() => handleClose()}>
        X
      </span>
      <div className="sighting-card--header">
        <div className="header-text">
          <h3>{headline}</h3>
          <h5>{dateofSighting}</h5>
          <h5>{locationText}</h5>
          <div className="rating">{believabilityRating}</div>
        </div>
        <GatsbyImage image={getImage(featureImage)} />
      </div>
      <div
        className="sighting-card--text"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  )
}
