import { graphql } from 'gatsby';
import React from 'react';
import AlienMap from '../components/AlienMap';
import Seo from '../components/seo';

export const query = graphql`
query MyQuery {
  sightings: allDatoCmsAlienSighting {
    nodes {
      dateOfSighting(formatString: "MM/DD/yyy")
      description
      featureImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      headline
      id
      location {
        latitude
        longitude
      }
      locationText
      believabilityRating
    }
  }
}
`

const AlienMapPage = ({data}) => {
    return (
        <>
        <Seo title="The truth is out there"/>
        <AlienMap data={data}/>
        </>
    )
}

export default AlienMapPage