import React, { useState } from "react"
import ReactPlayer from "react-player/youtube"
import "./index.scss"

export default function MusicPlayer() {
  const [play, setPlay] = useState(false)
  return (
    <div className="play-wrapper">
      <div class="btn-wrapper" onClick={() => setPlay(!play)}>
        <span class={`control ${play ? "pause-btn" : "play-btn"}`}></span>
      </div>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=Qz2wnSVeITg"
        controls={false}
        loop={true}
        playing={play ? true : false}
        width="5px"
        height="5px"
      />
    </div>
  )
}
