import React, { useState, useEffect, useRef } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import SightingCard from "./SightingCard"
import MusicPlayer from "./MusicPlayer"
import "./index.scss"
import Key from "./Key"

export default function AlienMap({ data }) {
  const sightings = data.sightings.nodes
  // = = = = = = = = = = =
  // Use States
  const [clickedPoint, setClickedPoint] = useState("")
  const [opened, setOpened] = useState(false)
  const [map, setMap] = useState(null)
  const mapContainerRef = useRef(null)

  // = = = = = = = = = = =
  // Map Section Data
  const MAPBOX_TOKEN =
    "pk.eyJ1Ijoib2theWtlbmRhbGwiLCJhIjoiY2wzZTlwenpyMDE0cDNpbjRveXA2aXJ5cyJ9.Vm5cUqJEIa6MjsP62ZabKg"

  //    = = = = = = = = = =
  //   Event Handlers
  const clickPoint = clicked => {
    const filteredPost = sightings.filter(
      sighting => sighting.headline === clicked
    )
    setClickedPoint(filteredPost[0])
    setOpened(true)
  }
  const handleClose = () => {
    setOpened(false)
    setClickedPoint("")
  }

  //  = = = = = = = = = =
  // Create JSON Data for Map
  const mapPoints = sightings?.map(sighting => {
    return {
      type: "Feature",
      properties: {
        name: sighting.headline,
        id: sighting.id,
        place: sighting.locationText,
        rating: sighting.believabilityRating,
      },
      geometry: {
        type: "Point",
        coordinates: [sighting.location.longitude, sighting.location.latitude],
      },
    }
  })

  const pointCollection = {
    type: "FeatureCollection",
    features: mapPoints,
  }

  //  = = = = = = = = = =
  // Map Init Styles
  const mapContainerStyle = {
    width: "100%",
    height: "100vh",
  }

  //  = = = = = = = = = =
  // Map Init Hook

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/okaykendall/cl3e9wue9002w14qgathoc8ri",
      center: [-90.155, 37.451],
      zoom: 3.05,
    })
    map.on("load", () => {
      map.addSource("points", {
        type: "geojson",
        data: pointCollection,
      })
      map.addLayer({
        id: "points",
        type: "circle",
        source: "points",
        paint: {
          "circle-radius": 8,
          "circle-color": [
            "match",
            ["get", "rating"],
            1,
            "#3170D1",
            2,
            "#9EE8EB",
            3,
            "#EB1821",
            4,
            "#CA58D1",
            5,
            "#fbb03b",
            /* other */ "#3170D1",
          ],
        },
        layout: {
          "circle-sort-key": 1,
        },
      })
      //Closure of onload function
    })
    // Helper Map Functions
    map.on("mouseenter", "points", () => {
      map.getCanvas().style.cursor = "pointer"
    })
    map.on("mouseleave", "points", () => {
      map.getCanvas().style.cursor = ""
    })

    // Click events here
    map.on("click", "points", e => {
      const clicked = e.features[0].properties.name
      clickPoint(clicked)
      console.log(e.features[0])
    })

    setMap(map)

    return () => map.remove()
  }, [])

  return (
    <div className="mapWrapper">
      <MusicPlayer />
      <Key />
      {clickedPoint ? (
        <SightingCard
          handleClose={handleClose}
          opened={opened}
          post={clickedPoint}
        />
      ) : null}
      <div ref={mapContainerRef} style={mapContainerStyle}></div>
    </div>
  )
}
